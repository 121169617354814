var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',[_c('b-media',{staticClass:"mb-2 ml-1"},[_c('b-row',[_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-col',[_c('b-row',{staticClass:"mb-1"},[_c('b-avatar',{staticClass:"badge-minimal image",attrs:{"size":"76","button":"","src":_vm.userData.avatar,"variant":"light-primary","square":"","badge-variant":"success"},on:{"click":_vm.handleClick}}),_c('h4',{staticClass:"ml-2 mb-1 text-center align-content-lg-center"},[_vm._v(" "+_vm._s(_vm.userData.name)+" ")])],1),_c('b-row',[_c('input',{ref:"inputFile",staticClass:"input-file",attrs:{"accept":"image/*","type":"file"},on:{"change":_vm.uploadFile}})]),_c('br')],1)],1)])],1),_c('b-form',[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Thông tin cơ bản ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Họ và tên","label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Họ và tên "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Họ và tên","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Ngày sinh","label-for":"dob"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ngày sinh "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Ngày sinh","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'd-m-Y'},"placeholder":"DD-MM-YYYY"},model:{value:(_vm.userData.dob),callback:function ($$v) {_vm.$set(_vm.userData, "dob", $$v)},expression:"userData.dob"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Số CCCD/CMND ","label-for":"identification"}},[_c('b-form-input',{attrs:{"id":"identification","disabled":""},model:{value:(_vm.userData.identification),callback:function ($$v) {_vm.$set(_vm.userData, "identification", $$v)},expression:"userData.identification"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Giới tính","label-for":"genderId","label-class":"mb-1"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Giới tính "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Giới tính","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"genderId","options":_vm.dataListGenders,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.genderId),callback:function ($$v) {_vm.$set(_vm.userData, "genderId", $$v)},expression:"userData.genderId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","placeholder":"nguyenvana@gmail.com"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Số điện thoại","label-for":"phone"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số điện thoại "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Số điện thoại","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone"},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Dân tộc","label-for":"ethnicId"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataListEthnics,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.ethnicId),callback:function ($$v) {_vm.$set(_vm.userData, "ethnicId", $$v)},expression:"userData.ethnicId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Tôn giáo","label-for":"religionId"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataListReligions,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.religionId),callback:function ($$v) {_vm.$set(_vm.userData, "religionId", $$v)},expression:"userData.religionId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Ảnh CCCD/CMND mặt trước","label-for":"file"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ảnh CCCD/CMND mặt trước "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Ảnh CCCD/CMND mặt trước","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"inputFileFrontRef",attrs:{"placeholder":_vm.userData.identificationFrontMediaName,"accept":"image/*"},on:{"change":_vm.uploadFront},model:{value:(_vm.userData.identificationFrontMediaId),callback:function ($$v) {_vm.$set(_vm.userData, "identificationFrontMediaId", $$v)},expression:"userData.identificationFrontMediaId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Ảnh CCCD/CMND mặt sau","label-for":"file"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ảnh CCCD/CMND mặt sau "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Ảnh CCCD/CMND mặt sau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"inputFileBackRef",attrs:{"placeholder":_vm.userData.identificationBackMediaName,"accept":"image/*"},on:{"change":_vm.uploadBack},model:{value:(_vm.userData.identificationBackMediaId),callback:function ($$v) {_vm.$set(_vm.userData, "identificationBackMediaId", $$v)},expression:"userData.identificationBackMediaId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Địa chỉ thường trú ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Tỉnh/Thành phố","label-for":"provinceCodePermanent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tỉnh/Thành phố "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tỉnh/Thành phố","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"provinceCodePermanent","options":_vm.dataListProvince,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectProvincePermanent},model:{value:(_vm.userData.provinceCodePermanent),callback:function ($$v) {_vm.$set(_vm.userData, "provinceCodePermanent", $$v)},expression:"userData.provinceCodePermanent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Quận/Huyện","label-for":"districtCodePermanent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Quận/Huyện "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Quận/Huyện","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"districtCodePermanent","options":_vm.dataListDistrictPermanent,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectDistrictPermanent},model:{value:(_vm.userData.districtCodePermanent),callback:function ($$v) {_vm.$set(_vm.userData, "districtCodePermanent", $$v)},expression:"userData.districtCodePermanent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Phường/Xã","label-for":"wardCodePermanent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phường/Xã "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Phường/Xã","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"wardCodePermanent","label":"label","options":_vm.dataListWardPermanent,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.wardCodePermanent),callback:function ($$v) {_vm.$set(_vm.userData, "wardCodePermanent", $$v)},expression:"userData.wardCodePermanent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Địa chỉ cụ thể","label-for":"addressPermanent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Địa chỉ cụ thể "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Địa chỉ cụ thể","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"addressPermanent"},model:{value:(_vm.userData.addressPermanent),callback:function ($$v) {_vm.$set(_vm.userData, "addressPermanent", $$v)},expression:"userData.addressPermanent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Địa chỉ liên hệ ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Tỉnh/Thành phố","label-for":"provinceCodeCurrent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tỉnh/Thành phố "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tỉnh/Thành phố","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"provinceCodeCurrent","label":"label","options":_vm.dataListProvince,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectProvinceCurrent},model:{value:(_vm.userData.provinceCodeCurrent),callback:function ($$v) {_vm.$set(_vm.userData, "provinceCodeCurrent", $$v)},expression:"userData.provinceCodeCurrent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Quận/Huyện","label-for":"districtCodeCurrent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Quận/Huyện "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Quận/Huyện","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"districtCodeCurrent","label":"label","options":_vm.dataListDistrictCurrent,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectDistrictCurrent},model:{value:(_vm.userData.districtCodeCurrent),callback:function ($$v) {_vm.$set(_vm.userData, "districtCodeCurrent", $$v)},expression:"userData.districtCodeCurrent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Phường/Xã","label-for":"wardCodeCurrent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phường/Xã "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Phường/Xã","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"wardCodeCurrent","label":"label","options":_vm.dataListWardCurrent,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.wardCodeCurrent),callback:function ($$v) {_vm.$set(_vm.userData, "wardCodeCurrent", $$v)},expression:"userData.wardCodeCurrent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Địa chỉ cụ thể","label-for":"addressCurrent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Địa chỉ cụ thể "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Địa chỉ cụ thể","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"addressCurrent"},model:{value:(_vm.userData.addressCurrent),callback:function ($$v) {_vm.$set(_vm.userData, "addressCurrent", $$v)},expression:"userData.addressCurrent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Nơi học Trung học Phổ thông ")])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('h6',{staticClass:"mb-0 ml-50",staticStyle:{"color":"#7367f0"}},[_vm._v(" Lớp 10 ")])]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Tỉnh/Thành phố","label-for":"provinceTenth"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tỉnh/Thành phố "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tỉnh/Thành phố","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"provinceTenth","label":"label","options":_vm.dataListProvince,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectProvinceTenth},model:{value:(_vm.userData.provinceTenth),callback:function ($$v) {_vm.$set(_vm.userData, "provinceTenth", $$v)},expression:"userData.provinceTenth"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Quận/Huyện","label-for":"districtTenth"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Quận/Huyện "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Quận/Huyện","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"districtTenth","label":"label","options":_vm.dataListDistrictTenth,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectDistrictTenth},model:{value:(_vm.userData.districtTenth),callback:function ($$v) {_vm.$set(_vm.userData, "districtTenth", $$v)},expression:"userData.districtTenth"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Trường Trung học Phổ thông","label-for":"highSchoolTenth"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trường Trung học Phổ thông "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trường Trung học Phổ thông","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"highSchoolTenth","label":"label","options":_vm.dataListHighSchoolTenth,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.highSchoolTenth),callback:function ($$v) {_vm.$set(_vm.userData, "highSchoolTenth", $$v)},expression:"userData.highSchoolTenth"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('h6',{staticClass:"mb-0 ml-50",staticStyle:{"color":"#7367f0"}},[_vm._v(" Lớp 11 ")])]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Tỉnh/Thành phố","label-for":"provinceEleventh"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tỉnh/Thành phố "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tỉnh/Thành phố","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"provinceEleventh","label":"label","options":_vm.dataListProvince,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectProvinceEleventh},model:{value:(_vm.userData.provinceEleventh),callback:function ($$v) {_vm.$set(_vm.userData, "provinceEleventh", $$v)},expression:"userData.provinceEleventh"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Quận/Huyện","label-for":"districtEleventh"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Quận/Huyện "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Quận/Huyện","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"districtEleventh","label":"label","options":_vm.dataListDistrictEleventh,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectDistrictEleventh},model:{value:(_vm.userData.districtEleventh),callback:function ($$v) {_vm.$set(_vm.userData, "districtEleventh", $$v)},expression:"userData.districtEleventh"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Trường Trung học Phổ thông","label-for":"highSchoolEleventh"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trường Trung học Phổ thông "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trường Trung học Phổ thông","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"highSchoolEleventh","options":_vm.dataListHighSchoolEleventh,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.highSchoolEleventh),callback:function ($$v) {_vm.$set(_vm.userData, "highSchoolEleventh", $$v)},expression:"userData.highSchoolEleventh"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('h6',{staticClass:"mb-0 ml-50",staticStyle:{"color":"#7367f0"}},[_vm._v(" Lớp 12 ")])]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Tỉnh/Thành phố","label-for":"provinceTwelfth"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tỉnh/Thành phố "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tỉnh/Thành phố","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"provinceTwelfth","label":"label","options":_vm.dataListProvince,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectProvinceTwelfth},model:{value:(_vm.userData.provinceTwelfth),callback:function ($$v) {_vm.$set(_vm.userData, "provinceTwelfth", $$v)},expression:"userData.provinceTwelfth"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Quận/Huyện","label-for":"districtTwelfth"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Quận/Huyện "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Quận/Huyện","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"districtTwelfth","label":"label","options":_vm.dataListDistrictTwelfth,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectDistrictTwelfth},model:{value:(_vm.userData.districtTwelfth),callback:function ($$v) {_vm.$set(_vm.userData, "districtTwelfth", $$v)},expression:"userData.districtTwelfth"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Trường Trung học Phổ thông","label-for":"highSchoolTwelfth"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trường Trung học Phổ thông "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trường Trung học Phổ thông","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"highSchoolTwelfth","options":_vm.dataListHighSchoolTwelfth,"reduce":function (option) { return option.value; }},model:{value:(_vm.userData.highSchoolTwelfth),callback:function ($$v) {_vm.$set(_vm.userData, "highSchoolTwelfth", $$v)},expression:"userData.highSchoolTwelfth"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)],1),_c('b-button',{staticClass:"mr-1",attrs:{"disabled":!_vm.checkRegisterDate,"type":"submit","variant":"primary"},on:{"click":_vm.onSave}},[_vm._v(" Lưu ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }