<template>
  <section id="card-style-variation">
    <h4 class="mt-1 mb-2">
      1. Lệ phí xét tuyển
    </h4>
    <b-card-code
      title="Danh sách tổng số nguyện vọng thí sinh đăng ký: "
    >
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
      >
        <div
          slot="emptystate"
          style="text-align: center; font-weight: bold"
        >
          Không có bản ghi nào !
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'chiPhiHoSo'">
            {{ formatCurrency(props.row.chiPhiHoSo) }}
          </span>
          <span v-else-if="props.column.field === 'chiPhiMon'">
            {{ formatCurrency(props.row.chiPhiMon) }}
          </span>
          <span v-else-if="props.column.field === 'chiPhiNguyenVong'">
            {{ formatCurrency(props.row.chiPhiNguyenVong) }}
          </span>
          <span v-else-if="props.column.field === 'tongTien'">
            {{ formatCurrency(props.row.tongTien) }}
          </span>
          <!-- Column: Common -->
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>

    </b-card-code>
    <h4
      class="mt-1 mb-2"
    >
      Tổng số tiền thí sinh cần thanh toán cho lệ phí xét tuyển là: <span style="color: red;">{{ formatCurrency(tongTien) }}</span>
    </h4>
    <h4 class="mt-1 mb-2">
      Số tiền đã thanh toán: <span style="color: red">{{ formatCurrency(tongTienDaThanhToan) }}</span>
    </h4>

    <h4
      v-if="tongTienChuaThanhToan > 0"
      class="mt-1 mb-2"
    >
      Số tiền phải thanh toán còn lại: <span style="color: red">{{ formatCurrency(tongTienChuaThanhToan) }}</span>
      <span class="ml-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="openPaymentTransaction()"
        >
          <feather-icon
            icon="CreditCardIcon"
            class=""
          />
          <span> Thanh toán</span>
        </b-button>
      </span>
    </h4>

    <h4 class="mt-2 mb-2">
      2. Loại lệ phí khác
    </h4>
    <b-modal
      id="modal-transaction"
      ref="modal-transaction"
      title="Thanh toán lệ phí xét tuyển"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <Payment
        :info-reception="studentInfoReception"
        @success="onTransactionSuccess"
      />
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import Payment from '@/views/payment/Payment.vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import VNnum2words from 'vn-num2words'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardCode,
    BButton,
    BRow,
    BCol,
    Payment,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      filter: {
        applicantStudentId: null,
        admissionRoundId: null,
        currentPage: 1,
        itemsPerPage: 1000,
        code: null,
        name: null,
        status: null,
      },
      studentInfoReception: {},
      rows: [],
      arrSubjectTest: [],
      tongTien: 0,
      tongTienDaThanhToan: 0,
      tongTienChuaThanhToan: 0,
    }
  },

  computed: {
    ...mapGetters({
      dataRegisterTest: 'registerTest/admissionRegisterTests',
    }),
    columns() {
      return [
        {
          label: 'Phương thức xét tuyển',
          field: 'ptxtName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tổng số nguyện vọng',
          field: 'soNguyenVong',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Chi phí theo hồ sơ',
          field: 'chiPhiHoSo',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Chi phí theo môn',
          field: 'chiPhiMon',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Chi phí theo nguyện vọng',
          field: 'chiPhiNguyenVong',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thành tiền',
          field: 'tongTien',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    this.filter.admissionRoundId = this.currentUser.admissionRoundId
    this.filter.applicantStudentId = this.currentUser.id
    const data = await this.getDataSources(this.filter)
    await this.getDataRegisterTest(this.filter)
    if (this.dataRegisterTest) {
      this.arrSubjectTest = this.dataRegisterTest.map(item => item.admissionSubjectId)
    }
    if (data) {
      this.rows = data.records
      // Bước 1: Tính toán số tiền cho mỗi phương thức xét tuyển (ptxtId)
      const results = data.records.reduce((acc, item) => {
        if (!acc[item.ptxtId]) {
          acc[item.ptxtId] = {
            ptxtId: item.ptxtId,
            ptxtName: item.ptxtName,
            soNguyenVong: new Set(),
            tongTien: 0,
            chiPhiHoSo: 0,
            chiPhiMon: 0,
            chiPhiNguyenVong: 0,
            khtIdSet: new Set(),
          }
        }

        acc[item.ptxtId].soNguyenVong.add(item.id)

        if (item.type == 0 && item.monId === null) {
          acc[item.ptxtId].chiPhiHoSo = item.gia
        } else if (item.type == 1) {
          if (!acc[item.ptxtId].khtIdSet.has(item.khtId)) {
            acc[item.ptxtId].khtIdSet.add(item.khtId)
            acc[item.ptxtId].chiPhiNguyenVong += item.gia
          }
        } else if (item.type == 0 && item.monId !== null && this.arrSubjectTest.includes(item.monId)) {
          acc[item.ptxtId].chiPhiMon = item.gia
        }
        return acc
      }, {})
      Object.values(results)
        .forEach(ptxt => {
          const soNguyenVong = ptxt.soNguyenVong.size
          ptxt.tongTien = ptxt.chiPhiHoSo + ptxt.chiPhiMon + (ptxt.chiPhiNguyenVong * soNguyenVong)
          this.tongTien += ptxt.tongTien
          ptxt.soNguyenVong = soNguyenVong
        })
      const ketQua = Object.values(results)
      this.rows = ketQua
    }
    if (this.tongTien > 0) {
      // thực hiện tạo phiếu thu bảng receipt
      await this.checkCreateReceipt()
    }
  },

  methods: {
    ...mapActions({
      getDataSources: 'aspiration/getReportAdmissionAspirations',
      getDataRegisterTest: 'registerTest/getAdmissionRegisterTests',
      createReceipt: 'receipt/createReceipt',
      getReceiptByRoundAndApplicant: 'receipt/getReceiptByRoundAndApplicant',
    }),

    async checkCreateReceipt() {
      const receipt = await this.getReceiptByRoundAndApplicant({
        admission_round_id: this.currentUser.admissionRoundId,
        applicant_student_id: this.currentUser.id,
        type: 1,
      })
      // check chưa tồn tại receipt
      if (receipt.length === 0) {
        await this.fncCreateReceipt()
      } else {
        const receiptPaid = receipt.filter(item => item.status === 2)
        if (receiptPaid) {
          this.tongTienDaThanhToan = receiptPaid.reduce((total, item) => {
            return (total + item.amount)
          }, 0)
          const receiptUnpaid = receipt.find(item => item.status !== 2)
          const idReceiptUnpaid = receiptUnpaid ? receiptUnpaid.id : null
          if (receiptPaid) {
            this.tongTienChuaThanhToan = this.tongTien - this.tongTienDaThanhToan
          }
          if (this.tongTien > this.tongTienDaThanhToan) {
            await this.fncCreateReceipt(idReceiptUnpaid)
          }
          // eslint-disable-next-line prefer-destructuring
          // this.studentInfoReception = receiptUnpaid[0]
        }
      }
    },

    async fncCreateReceipt(idReceipt) {
      let param = {
        admissionRoundId: this.currentUser.admissionRoundId,
        applicantStudentId: this.currentUser.id,
        reason: 'Nộp tiền đăng ký tuyển sinh',
        total: this.tongTien - this.tongTienDaThanhToan,
        amount: this.tongTien - this.tongTienDaThanhToan,
        amountWord: this.getAmountWord(this.tongTien),
        type: 1,
        status: 1,
      }
      if (idReceipt) {
        param = { ...param, ...{ id: idReceipt } }
      }
      const res = await this.createReceipt(param)

      if (res.isSuccessful) {
        this.studentInfoReception = res.data
      }
    },

    getAmountWord(amount) {
      if (amount !== null) {
        // eslint-disable-next-line camelcase
        const amount_word = VNnum2words(amount)
        return `${
          amount_word.charAt(0).toUpperCase() + amount_word.slice(1)
        } đồng`
      }
      return null
    },

    formatCurrency(value) {
      if (!value) return '0 Vnđ'
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
      })
      return formatter.format(value)
        .replace('₫', 'Vnđ')
    },

    openPaymentTransaction() {
      this.$bvModal.show('modal-transaction')
    },

    async onTransactionSuccess() {
      this.$bvModal.hide('modal-transaction')
    },
  },
}
</script>
