<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      back-button-text="Trước"
      next-button-text="Tiếp"
      finish-button-text="Hoàn thành"
      class="mb-3"
    >
      <tab-content title="Thông tin cá nhân">
        <user-edit-tab-account :user-data="userDataTemp" class="mt-2 pt-75" />
      </tab-content>
      <tab-content title="Thông tin phục vụ xét tuyển">
        <user-edit-tab-information :user-data="userDataTemp" class="mt-2 pt-75" />
      </tab-content>
      <tab-content v-for="(tab, index) in admissionScoreTypes" :key="index" :title="tab.label">
        <user-edit-score class="mt-2 pt-75" :admission-score-type-id="tab.value" :admission-score-type-name="tab.label" />
      </tab-content>
      <tab-content title="Đăng ký nguyện vọng">
        <user-edit-tab-aspiration class="mt-2 pt-75" />
      </tab-content>
      <tab-content title="Lệ phí xét tuyển">
        <user-tab-payment class="mt-2 pt-75" />
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import UserEditTabAspiration from '@/views/applicant-student/UserEditTabAspiration.vue'
import UserTabPayment from '@/views/applicant-student/UserTabPayment.vue'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditScore from './UserEditScore.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    ToastificationContent,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabAspiration,
    UserEditScore,
    UserTabPayment,
  },
  data() {
    return {
      currentUser: getUser(),
      userData: {
        admissionRoundId: null,
        id: null,
        name: null,
        dob: null,
        identification: null,
        genderId: null,
        email: null,
        phone: null,
        ethnicId: null,
        religionId: null,
        identificationFrontMediaId: null,
        identificationBackMediaId: null,
        identificationFrontMediaName: null,
        identificationBackMediaName: null,
        confirmationMediaName: null,
        provinceCodePermanent: null,
        districtCodePermanent: null,
        wardCodePermanent: null,
        addressPermanent: null,
        provinceCodeCurrent: null,
        districtCodeCurrent: null,
        wardCodeCurrent: null,
        addressCurrent: null,
        provinceTenth: null,
        districtTenth: null,
        highSchoolTenth: null,
        provinceEleventh: null,
        districtEleventh: null,
        highSchoolEleventh: null,
        provinceTwelfth: null,
        districtTwelfth: null,
        highSchoolTwelfth: null,
        avatarMediaId: null,
        avatar: require('@/assets/images/avatars/default_avatar.jpg'),
        graduatedYear: null,
        factPriorityZoneId: null,
        factAdmissionConfirmationTypeId: null,
        confirmationMediaId: null,
        factAcademicPerformanceTenth: null,
        factAdmissionConductTenth: null,
        factAcademicPerformanceEleventh: null,
        factAdmissionConductEleventh: null,
        factAcademicPerformanceTwelfth: null,
        factAdmissionConductTwelfth: null,
        gpaTenth: null,
        gpaEleventh: null,
        gpaTwelfth: null,
        factAdmissionCertificateTypeId: null,
        subjectCertificateId: null,
        rankCertificate: null,
        yearCertificate: null,
        certificateMediaId: null,
        certificateMediaName: null,
        factAdmissionLanguageCertificateTypeId: null,
        scoreLanguageCertificate: null,
        dateLanguageCertificate: null,
        languageCertificateMediaId: null,
        languageCertificateMediaName: null,
        status: 1,
      },
      userDataTemp: {},
    }
  },
  computed: {
    ...mapGetters({
      dataApplicantStudentDetail: 'applicantStudent/dataApplicantStudentDetail',
      admissionScoreTypes: 'academicTranscriptScore/admissionScoreTypes'
    }),
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    ...mapActions({
      getApplicantStudentDetail: 'applicantStudent/getApplicantStudentDetail',
      getLinkAvatar: 'applicantStudent/getLinkAvatar',
      getAdmissionScoreTypes: 'academicTranscriptScore/getAdmissionScoreTypes'
    }),
    async onLoad() {
      this.isLoading = true
      await this.getAdmissionScoreTypes()
      try {
        this.userData.avatar = require('@/assets/images/avatars/default_avatar.jpg')
        this.userData.name = this.currentUser.name
        this.userData.id = this.currentUser.id
        this.userData.identification = this.currentUser.identification
        this.userData.email = this.currentUser.email
        this.userData.phone = this.currentUser.phone
        this.userData.admissionRoundId = this.currentUser.admissionRoundId
        await this.getApplicantStudentDetail({ id: this.currentUser.id })
        if (this.dataApplicantStudentDetail && this.dataApplicantStudentDetail.length > 0) {
          this.userData.provinceCodePermanent = this.dataApplicantStudentDetail[0].provinceCodePermanent
          this.userData.provinceCodeCurrent = this.dataApplicantStudentDetail[0].provinceCodeCurrent
          this.userData.provinceTenth = this.dataApplicantStudentDetail[0].provinceTenth
          this.userData.provinceEleventh = this.dataApplicantStudentDetail[0].provinceEleventh
          this.userData.provinceTwelfth = this.dataApplicantStudentDetail[0].provinceTwelfth
          this.userData.districtCodePermanent = this.dataApplicantStudentDetail[0].districtCodePermanent
          this.userData.districtCodeCurrent = this.dataApplicantStudentDetail[0].districtCodeCurrent
          this.userData.districtTenth = this.dataApplicantStudentDetail[0].districtTenth
          this.userData.districtEleventh = this.dataApplicantStudentDetail[0].districtEleventh
          this.userData.districtTwelfth = this.dataApplicantStudentDetail[0].districtTwelfth
          this.userData.wardCodePermanent = this.dataApplicantStudentDetail[0].wardCodePermanent
          this.userData.wardCodeCurrent = this.dataApplicantStudentDetail[0].wardCodeCurrent
          // eslint-disable-next-line radix
          this.userData.highSchoolTenth = parseInt(this.dataApplicantStudentDetail[0].highSchoolTenth)
          this.userData.highSchoolEleventh = parseInt(this.dataApplicantStudentDetail[0].highSchoolEleventh)
          this.userData.highSchoolTwelfth = parseInt(this.dataApplicantStudentDetail[0].highSchoolTwelfth)
          this.userData.genderId = this.dataApplicantStudentDetail[0].genderId
          this.userData.ethnicId = this.dataApplicantStudentDetail[0].ethnicId
          this.userData.religionId = this.dataApplicantStudentDetail[0].religionId
          this.userData.factPriorityZoneId = this.dataApplicantStudentDetail[0].factPriorityZoneId
          this.userData.graduatedYear = this.dataApplicantStudentDetail[0].graduatedYear
          this.userData.dob = this.dataApplicantStudentDetail[0].dob
          this.userData.identificationFrontMediaName = this.dataApplicantStudentDetail[0].identificationFrontMediaName
          this.userData.identificationBackMediaName = this.dataApplicantStudentDetail[0].identificationBackMediaName
          this.userData.factAdmissionConfirmationTypeId = this.dataApplicantStudentDetail[0].factAdmissionConfirmTypeId
          this.userData.confirmationMediaName = this.dataApplicantStudentDetail[0].confirmationMediaName
          this.userData.certificateMediaName = this.dataApplicantStudentDetail[0].certificateMediaName
          this.userData.languageCertificateMediaName = this.dataApplicantStudentDetail[0].languageCertificateMediaName
          this.userData.subjectCertificateId = this.dataApplicantStudentDetail[0].subjectCertificateId
          this.userData.rankCertificate = this.dataApplicantStudentDetail[0].rankCertificate
          this.userData.yearCertificate = (this.dataApplicantStudentDetail[0].yearCertificate ? this.dataApplicantStudentDetail[0].yearCertificate.toString() : "")
          this.userData.factAdmissionCertificateTypeId = this.dataApplicantStudentDetail[0].factAdmissionCertificateTypeId
          this.userData.factAdmissionLanguageCertificateTypeId = this.dataApplicantStudentDetail[0].factAdmissionLanguageCertificateTypeId
          this.userData.scoreLanguageCertificate = (this.dataApplicantStudentDetail[0].scoreLanguageCertificate ? this.dataApplicantStudentDetail[0].scoreLanguageCertificate.toString() : "")
          this.userData.dateLanguageCertificate = this.dataApplicantStudentDetail[0].dateLanguageCertificate
          
          this.userData.factAcademicPerformanceTenth = this.dataApplicantStudentDetail[0].factAcademicPerformanceTenth
          this.userData.factAdmissionConductTenth = this.dataApplicantStudentDetail[0].factAdmissionConductTenth
          this.userData.factAcademicPerformanceEleventh = this.dataApplicantStudentDetail[0].factAcademicPerformanceEleventh
          this.userData.factAdmissionConductEleventh = this.dataApplicantStudentDetail[0].factAdmissionConductEleventh
          this.userData.factAcademicPerformanceTwelfth = this.dataApplicantStudentDetail[0].factAcademicPerformanceTwelfth
          this.userData.factAdmissionConductTwelfth = this.dataApplicantStudentDetail[0].factAdmissionConductTwelfth
          
          this.userData.gpaTenth = this.dataApplicantStudentDetail[0].gpaTenth
          this.userData.gpaEleventh = this.dataApplicantStudentDetail[0].gpaEleventh
          this.userData.gpaTwelfth = this.dataApplicantStudentDetail[0].gpaTwelfth
          
          const { data } = await this.getLinkAvatar(this.dataApplicantStudentDetail[0].avatar)
          this.userData.avatar = data
        }
        this.userDataTemp = {...this.userData}
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style>
.vue-form-wizard .wizard-navigation .wizard-nav li:not(:first-child) a::before {
  content: ">" !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after {
  content: ">" !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before {
  content: "<" !important;
}
</style>
