<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm mới nguyện vọng xét tuyển' : 'Cập nhật nguyện vọng xét tuyển'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <span class="text-danger">Thí sinh phải sắp xếp nguyện vọng theo thứ tự ưu tiên từ cao xuống thấp.</span>
        <b-form-group label-for="admissionFormId">
          <template v-slot:label>
            Phương thức xét tuyển <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Phương thức xét tuyển"
            rules="required"
          >
            <v-select
              v-model="targetItem.admissionFormId"
              :options="dataListAdmissionForm"
              :reduce="option => option.value"
              @input="selectAdmissionForm"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="admissionMajorId">
          <template v-slot:label>
            Ngành ứng tuyển <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Ngành ứng tuyển"
            rules="required"
          >
            <v-select
              v-model="targetItem.admissionMajorId"
              :options="dataListMajorByAdmissionForm"
              :reduce="option => option.value"
              @input="selectMajor"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group v-show="!checkBlock" label-for="admissionBlockId">
          <template v-slot:label>
            Tổ hợp môn xét tuyển <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tổ hợp môn xét tuyển"
          >
            <v-select
              v-model="targetItem.admissionBlockId"
              :options="dataListAdmissionBlockByAdmissionMajorForm"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự ưu tiên
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự ưu tiên"
          >
            <b-form-input
              id="orderNo"
              v-model="targetItem.orderNo"
              disabled
              name="orderNo"
              placeholder="Thứ tự ưu tiên"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { STATUSES } from '@/const/status'
import { Flag } from '@/const/flag'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'Save',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      targetItem: {
        applicantStudentId: null,
        admissionFormId: null,
        admissionMajorId: null,
        admissionBlockId: null,
        orderNo: 1,
        status: Flag.ACTIVE,
      },
      checkBlock: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      dataListAdmissionForm: 'applicantStudent/dataListAdmissionForm',
      dataListMajorByAdmissionForm: 'applicantStudent/dataListMajorByAdmissionForm',
      dataListAdmissionBlockByAdmissionMajorForm: 'applicantStudent/dataListAdmissionBlockByAdmissionMajorForm',
      maxOrderNo: 'aspiration/maxOrderNo',
      codeAdmissionForm: 'applicantStudent/codeAdmissionForm'
    }),
    isCreated() {
      return !this.dataSource
    },
    statuses() {
      return STATUSES
    },
  },
  methods: {
    ...mapActions({
      getDataListAdmissionForm: 'applicantStudent/getDataListAdmissionForm',
      getMajorByAdmissionFormId: 'applicantStudent/getMajorByAdmissionFormId',
      getAdmissionBlockByAdmissionMajorFormId: 'applicantStudent/getAdmissionBlockByAdmissionMajorFormId',
      createAspirations: 'aspiration/createAspirations',
      updateAspirations: 'aspiration/updateAspirations',
      getMaxOrderNo: 'aspiration/getMaxOrderNo',
      checkDuplicateAspiration: 'aspiration/checkDuplicateAspiration',
      checkCriteriaSet: 'aspiration/checkCriteriaSet',
      getCodeByAdmissionForm: 'applicantStudent/getCodeByAdmissionForm',
    }),
    async selectAdmissionForm() {
      const admissionFormId = this.targetItem.admissionFormId;
      const applicantStudentId = this.currentUser.id;
      
      await Promise.all([
        this.getMajorByAdmissionFormId({ admissionFormId }),
        this.getCodeByAdmissionForm({ admissionFormId }),
        this.getMaxOrderNo({ applicantStudentId, admissionFormId })
      ]).then(results => {
        this.checkBlock = this.codeAdmissionForm[0].factAdmissionFormTypeCode === 'TUYENTHANG';
        if (this.isCreated) {
          this.targetItem.orderNo = this.maxOrderNo + 1;
        }
      }).catch(error => {
        console.error("Error in selectAdmissionForm:", error);
      });
    },
    async selectMajor(majorId) {
      await this.getAdmissionBlockByAdmissionMajorFormId({
        admissionRoundId: this.currentUser.admissionRoundId,
        majorId,
        admissionFormId: this.targetItem.admissionFormId,
      })
      if (this.dataListAdmissionBlockByAdmissionMajorForm.length === 0) {
        this.targetItem.admissionBlockId = null
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.dataSource) {
        this.targetItem = { ...this.dataSource }
        this.targetItem.admissionFormId = this.dataSource.admisisonFormId
        this.targetItem.admissionMajorId = this.dataSource.admissionMajorId
        this.targetItem.admissionBlockId = this.dataSource.admissionBlockId
        await this.getDataListAdmissionForm({ admissionRoundId: this.currentUser.admissionRoundId })
        await this.selectAdmissionForm()
        await this.selectMajor(this.dataSource.admissionMajorId)
      } else {
        await this.getDataListAdmissionForm({ admissionRoundId: this.currentUser.admissionRoundId })
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = { status: Flag.ACTIVE }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        this.targetItem.applicantStudentId = this.currentUser.id
        const response = await this.checkDuplicateAspiration(this.targetItem)
        const responseCriteria = await this.checkCriteriaSet({
          aspirationInformation: this.targetItem,
          checkRequired: 0
        })
        if (!response.check) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thí sinh đăng ký nguyện vọng bị trùng!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          this.$bvModal.hide('saveModal')
          this.isLoading = false
        }
        if (!responseCriteria.checkCriteria) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thí sinh không đủ điều kiện đầu vào cho đăng ký ngành! Vui lòng cập nhật đầy đủ thông tin ứng tuyển.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          this.$bvModal.hide('saveModal')
          this.isLoading = false
        }
        if (response.check && responseCriteria.checkCriteria) {
          try {
            // eslint-disable-next-line no-shadow
            const response = this.isCreated
              ? await this.createAspirations(this.targetItem)
              : await this.updateAspirations(this.targetItem)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                if (type === 'hide') {
                  this.$bvModal.hide('saveModal')
                }
                this.$emit('succeed')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      }
    },
  },
}
</script>
